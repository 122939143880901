<template>
  <basic-container>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.plat.sys.param.title.indexHeadTitle')"
      v-on="onHeadEvent">
    </head-layout>
    <grid-head-layout ref="searchFrom" v-model="searchForm" :search-columns="searchColumns"
                      v-on="onSearchEvent">
    </grid-head-layout>
    <grid-layout
      :gridRowBtn="gridRowBtn"
      v-bind="bindVal"
      v-on="onEvent"
      @gird-handle-select-click="selectionChange">
    </grid-layout>
  </basic-container>
</template>

<script>

import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

export default window.$crudCommon({
  data() {
    return {}
  },
  components: {
    GridLayout,
    HeadLayout,
  },
  computed: {
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.param_add, false),
        viewBtn: this.vaildData(this.permission.param_view, false),
        delBtn: this.vaildData(this.permission.param_delete, false),
        editBtn: this.vaildData(this.permission.param_edit, false)
      };
    },
    onHeadEvent() {
      return {
        'head-add': this.clickHeadAdd,
        'head-romve': this.clickHeadRomve,
      }
    },
    onEvent() {
      return {
        'grid-view': this.rowView,
        'grid-edit': this.clickRowEdit,
        'grid-romve': this.rowDel,
      }
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.param_add) {
        result.push( {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.param_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-romve",
          type: "button",
          icon: ""
        });
      }
      return result;
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.param_view) {
        result.push({
          label: this.$t('cip.cmn.btn.viewBtn'),
          emit: "grid-view",
          type: "text",
          icon: ""
        });
      }
      if (this.permission.param_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          type: "text",
          icon: ""
        });
      }
      if (this.permission.param_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          type: "text",
          icon: ""
        });
      }
      return result;
    }
  },
  methods: {
    selectionChange(list) {
      this.selectionList = list;
      console.log(this.selectionList)
    },
    clickHeadAdd() {
      this.$router.push({
        path: '/system/param/add',
        query: {
          type: 'add',
        }
      });
    },
    //修改
    clickRowEdit(row) {
      this.$router.push({
        path: '/system/param/edit',
        query: {
          id: row.id,
          type: 'edit',
        }
      })
    },
    rowView(row) {
      this.$router.push({
        path: '/system/param/view',
        query: {
          id: row.id,
          type: 'view',
        }
      })
    },
  }
}, {
  //模块路径
  name: 'system/param',
})
</script>

<style>
</style>
