var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "basic-container",
    [
      _c(
        "head-layout",
        _vm._g(
          {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": _vm.$t("cip.plat.sys.param.title.indexHeadTitle"),
            },
          },
          _vm.onHeadEvent
        )
      ),
      _c(
        "grid-head-layout",
        _vm._g(
          {
            ref: "searchFrom",
            attrs: { "search-columns": _vm.searchColumns },
            model: {
              value: _vm.searchForm,
              callback: function ($$v) {
                _vm.searchForm = $$v
              },
              expression: "searchForm",
            },
          },
          _vm.onSearchEvent
        )
      ),
      _c(
        "grid-layout",
        _vm._g(
          _vm._b(
            {
              attrs: { gridRowBtn: _vm.gridRowBtn },
              on: { "gird-handle-select-click": _vm.selectionChange },
            },
            "grid-layout",
            _vm.bindVal,
            false
          ),
          _vm.onEvent
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }